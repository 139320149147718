import { useEffect, useState } from "react";
import { Logo } from "./logo";
import { ChengeLang } from "./ChangeLang/changeLang";

export const Footer = () => {
  const [cords, setCords] = useState({ x: 0, y: 0 });
  useEffect(() => {
    const event = (ev: MouseEvent): any => {
      setCords({
        x: 0.5 - ev.clientX / window.innerHeight,
        y: ev.clientY / window.innerWidth,
      });
    };
    document.addEventListener("mousemove", event);
    return () => {
      document.removeEventListener("mousemove", event);
    };
  }, []);
  return (
    <footer
      style={{
        filter: `drop-shadow(${cords.x * 10}px ${cords.y * 10}px ${
          Math.abs(cords.x * 12 * cords.y * 12) + 10
        }px rgb(250 0 230 ))`,
      }}
    >
      <ChengeLang />
      <Logo></Logo>
    </footer>
  );
};
