import { FC } from "react";
import "./contacts.css";
import { useTranslation } from "react-i18next";
export const Contacts: FC = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="contacts">
      <div className="rightMobile">
        <div>{t("Contact us", "Contact us:")}</div>
      </div>
      <div className="email">
        yamayamayamabyss@<span>gmail.com</span>
      </div>
      <div className="emailMobile">
        <div>yamayama</div>
        <div>
          yamabyss<span>@</span>
        </div>
        <span>gmail.com</span>
      </div>
      <div className="right">
        {t(
          "Contact us full",
          "If you have any ideas for cooperation, please, do not hesitate to contact us"
        )}
      </div>
    </div>
  );
};
