import { FC } from "react";

export const Logo: FC = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 131 1191"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_113_20)">
        <path
          d="M0.247995 132.797C0.742995 132.797 1.484 132.797 1.979 132.797C39.5484 132.797 69.9503 120.524 93.1842 95.7317C116.418 71.1851 128.035 39.2745 128.035 0L80.8257 -2.06359e-06C80.8257 24.5466 73.4106 43.6929 58.5804 57.9299C43.9974 71.676 24.965 78.549 1.979 78.549C1.484 78.549 0.742998 78.549 0.247998 78.549L0.247995 132.797Z"
          fill="#020000"
        />
        <path
          d="M9.88596 203.982C13.594 211.591 15.818 218.955 16.807 226.319C17.796 233.438 20.515 238.838 24.716 242.766C28.918 246.693 34.356 248.657 40.5353 248.657C46.7145 248.657 51.9051 246.938 56.107 243.256C60.0617 239.574 62.0391 234.911 62.0391 228.774C62.0391 226.074 61.0504 221.901 59.073 216.746C55.1183 206.437 46.7145 196.618 33.8617 187.045L37.3221 187.536C44.2428 187.536 49.9277 185.081 54.624 180.417C59.3202 175.753 61.5447 169.862 61.5447 163.235C61.5447 156.607 59.073 151.207 54.3768 146.543C49.6806 141.879 43.7485 139.67 37.0749 139.67L0.493965 139.67L0.493963 190.236C3.70696 193.672 6.91996 198.336 9.88596 203.982Z"
          fill="#020000"
        />
        <path
          d="M13.099 296.032C21.008 303.887 24.716 313.951 24.716 326.715C24.716 340.216 20.761 350.771 13.099 358.626C9.14404 362.553 4.94203 365.499 -0.00096698 367.708L-0.000969008 414.101C13.841 410.173 26.199 402.564 37.0745 391.764C54.3764 374.581 63.0273 352.734 63.0273 326.715C63.0273 299.714 53.8821 277.376 35.3443 259.457C24.963 249.393 13.099 242.275 0.246048 237.856L0.246046 286.95C4.94205 289.159 9.39104 292.35 13.099 296.032Z"
          fill="#020000"
        />
        <path
          d="M46.7141 556.716C57.5896 542.233 63.0273 523.087 63.0273 499.768C63.0273 474.73 56.3538 452.393 43.2538 432.51C39.7934 426.865 34.3556 423.919 27.435 423.919C22.491 423.919 18.042 425.637 14.335 429.074C10.627 432.51 8.89705 436.929 8.89705 441.838C8.89705 445.274 9.88604 448.466 11.616 451.166C19.773 464.175 23.727 477.676 23.727 491.422C23.727 503.45 21.008 513.023 15.324 520.387C11.122 525.787 6.17803 528.978 -0.00096596 530.451L-0.000968074 578.808C21.256 577.335 36.8273 570.217 46.7141 556.716Z"
          fill="#020000"
        />
        <path
          d="M100.598 716.269C109.743 712.833 116.911 706.942 122.596 699.087C128.034 690.986 131 682.15 131 672.331C131 662.512 128.281 653.676 122.596 645.575C117.158 637.475 109.743 631.829 100.598 628.393L0.246994 589.854L0.246992 643.857L81.8132 672.331L0.246989 700.805L0.246987 754.807L100.598 716.269Z"
          fill="#020000"
        />
        <path
          d="M103.81 814.947C111.225 814.947 117.404 812.492 122.594 807.337C127.785 802.183 130.504 796.046 130.504 788.682C130.504 781.072 127.785 774.69 122.594 769.536C117.404 764.381 110.978 761.681 103.81 761.681L-0.0019989 761.681L-0.00200123 814.947L103.81 814.947Z"
          fill="#020000"
        />
        <path
          d="M7.91104 1095.27C25.955 1095.27 39.7962 1090.6 49.1887 1081.52C58.5811 1072.44 63.2773 1059.18 63.2773 1042C63.2773 1029.48 60.0641 1017.46 53.6377 1005.92C47.2113 994.382 39.3019 985.545 29.909 979.654C52.1547 972.044 63.2773 955.353 63.2773 929.333C63.2773 916.815 60.3113 905.032 54.1321 893.986C48.2 883.186 41.7736 875.331 35.3472 870.421L37.3245 870.421C44.2453 870.421 49.9302 867.967 54.6264 863.303C59.3226 858.639 61.7943 852.748 61.7943 846.12C61.7943 839.493 59.3226 834.093 54.6264 829.429C49.9302 824.765 43.9981 822.556 37.3245 822.556L0.496048 822.556L0.496046 871.158C5.93405 875.331 10.63 880.486 14.585 886.622C18.787 893.495 20.764 900.368 20.764 907.732C20.764 922.706 14.091 931.542 0.496043 933.997L0.496041 983.581C13.843 993.645 20.764 1005.92 20.764 1020.4C20.764 1035.37 14.091 1044.21 0.496038 1046.67L0.496036 1095.51L7.91104 1095.51L7.91104 1095.27Z"
          fill="#020000"
        />
        <path
          d="M13.349 1160.32C21.258 1168.17 24.966 1178.24 24.966 1191C24.966 1191.25 63.0297 1191 63.0297 1191C63.0297 1191 66.2429 1150.25 35.3467 1123.74C24.471 1114.41 13.101 1106.56 0.249027 1102.14L0.249025 1150.99C4.94503 1153.2 9.39403 1156.39 13.349 1160.32Z"
          fill="#020000"
        />
      </g>
      <defs>
        <clipPath id="clip0_113_20">
          <rect
            width="1191"
            height="131"
            fill="white"
            transform="translate(131) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
