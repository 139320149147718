import { FC, useEffect, useRef, useState } from "react";
import "./answer.css";
import {
  TelegramShareButton,
  TelegramIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  TwitterShareButton,
  XIcon,
  InstapaperShareButton,
} from "react-share";

import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { idState } from "../../store";
import userEvent from "@testing-library/user-event";
import { useParams } from "react-router-dom";

interface AnswerType {
  answer: string;
  image: string;
  text: string;
  onClick: () => Promise<void>;
}

export const Answer: FC<AnswerType> = ({ answer, onClick, image, text }) => {
  const img = useRef<HTMLImageElement>(null);
  const [load, setLoad] = useState(false);
  const { t, i18n } = useTranslation();
  let params = useParams();
  const { id } = params;
  const shareUrl = `https://dreaime.com/dream/${id}`;
  const shareBtn = useRef<HTMLAnchorElement>(null);
  const title = t("shareText", "Dreaime told me about my dream!");
  useEffect(() => {
    if (shareBtn.current) {
      shareBtn.current.addEventListener("click", async function () {
        if (navigator.share) {
          try {
            await navigator.share({
              title: text,
              text: title,
              url: shareUrl,
            });
          } catch (e) {
            console.log(e);
          }
        }
      });
    }
  }, [shareUrl, title]);
  useEffect(() => {
    if (!image || !answer) {
      setLoad(false);
    }
    const imgElement = img.current;
    if (imgElement) {
      imgElement.onload = (e) => {
        setLoad(true);
        console.log("load");
      };
    }
  }, []);
  const textTranslate = [
    "You should describe your dream",
    `Sorry, I can't explain this dream. Can you formulate it more specific`,
  ];

  return (
    <div className="answerCont">
      <title>Dreaime</title>
      <meta name="description" content={text} />
      <meta property="og:title" content={answer} />
      <meta property="og:image" content={`${image}`} />
      <meta property="title" content={answer} />
      <meta property="image" content={`${image}`} />
      <div className="answer">
        <div className="answerTextCont">
          <div className="answerYou">
            {text && <b>{t("YOU", "YOU:")}</b>}
            {" " + text}
          </div>
          {textTranslate.includes(answer)
            ? t(answer, answer)
            : answer
                .split(".")
                .filter((el) => el.length > 2)
                .map((text, i) => (
                  <span style={{ animationDelay: `${i * 1.4}s` }}>{text}.</span>
                ))}
        </div>
        <div
          className="answerImageCont"
          style={{ width: 512, opacity: load ? 1 : 0 }}
        >
          <a download={`dreaime_${text}.png`} href={`${image}`}>
            <img
              ref={img}
              className="answerImage"
              src={`${image}`}
              alt={"pic"}
            />
          </a>
          <div className="answerImageSharedButtonCont">
            <a download={`dreaime_${text}.png`} href={`${image}`}>
              <svg
                width="34"
                height="34"
                viewBox="0 0 372 369"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M258.974 33L113.006 33C79.405 33 58.2039 69.1411 74.5998 98.4702L146.584 227.237C163.264 257.074 206.112 257.326 223.141 227.686L297.125 98.9201C313.979 69.5868 292.804 33 258.974 33Z"
                  stroke="#A93DFE"
                  stroke-width="66"
                />
                <path
                  d="M339 336L33 336"
                  stroke="#A93DFE"
                  stroke-width="66"
                  stroke-linecap="round"
                />
              </svg>
            </a>
            <div className="answerButtonCont">
              <button className="beackButton" onClick={onClick}>
                {t("Again", "Again")}
              </button>
            </div>
            <a ref={shareBtn}>
              <svg
                width="34"
                height="34"
                viewBox="0 0 372 369"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ transform: "rotate(180deg)" }}
              >
                <path
                  d="M258.974 33L113.006 33C79.405 33 58.2039 69.1411 74.5998 98.4702L146.584 227.237C163.264 257.074 206.112 257.326 223.141 227.686L297.125 98.9201C313.979 69.5868 292.804 33 258.974 33Z"
                  stroke="#A93DFE"
                  stroke-width="66"
                />
                <path
                  d="M339 336L33 336"
                  stroke="#A93DFE"
                  stroke-width="66"
                  stroke-linecap="round"
                />
              </svg>
            </a>
            {/* <FacebookMessengerShareButton
              url={shareUrl}
              appId="521270401588372"
              className="Demo__some-network__share-button"
            >
              <svg
                width="32"
                height="42"
                viewBox="0 0 230 445"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M195 35V35C146.399 35 107 74.3989 107 123V410"
                  stroke="white"
                  stroke-width="69"
                  stroke-linecap="round"
                />
                <path
                  d="M35 158H186"
                  stroke="white"
                  stroke-width="69"
                  stroke-linecap="round"
                />
              </svg>
            </FacebookMessengerShareButton>

            <TelegramShareButton
              url={shareUrl}
              title={title}
              className="Demo__some-network__share-button"
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 349 347"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M46.4766 185.227L162.238 300.988C184.517 323.267 222.307 315.594 234.133 286.391L311.836 94.5122C326.345 58.6834 290.915 22.8993 254.944 37.0519L61.4798 113.169C31.985 124.773 24.0644 162.814 46.4766 185.227Z"
                  stroke="white"
                  stroke-width="66"
                />
              </svg>
            </TelegramShareButton>
            <TwitterShareButton
              url={shareUrl}
              title={title}
              className="Demo__some-network__share-button"
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 287 287"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34.707 34.8438L252.501 252.638"
                  stroke="white"
                  stroke-width="68"
                  stroke-linecap="round"
                />
                <path
                  d="M252.5 34.8438L34.7057 252.638"
                  stroke="white"
                  stroke-width="68"
                  stroke-linecap="round"
                />
              </svg>
            </TwitterShareButton> */}
          </div>
        </div>
      </div>
    </div>
  );
};
