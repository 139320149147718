import { useLocation, useNavigate, useParams } from "react-router-dom";
import { text } from "stream/consumers";
import { Answer } from "../ui/answer/answer";
import { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { bgLoadState } from "../store";
import { useGetData } from "../ui/input/hook";
import { env } from "process";

export const Dream = memo(
  () => {
    let params = useParams();
    const { id } = params;
    const content = useRef<HTMLDivElement>(null);
    const setLoad = useSetRecoilState(bgLoadState);
    const [image, setImage] = useState("");
    const [answer, setAnswer] = useState("");
    const [text, setText] = useState("");
    const { t, i18n } = useTranslation();
    const { click } = useGetData({ setAnswer, setImage, text });
    const navigate = useNavigate();
    const loadImage = useRef(false);
    useEffect(() => {
      if (!loadImage.current) {
        (async () => {
          console.log(loadImage.current);
          const rawResponse = await fetch(
            "https://server.dreaime.com:8383/text/getPost",
            {
              method: "POST",
              mode: "cors",

              headers: {
                "Content-Type": "application/json",
              }, // Assuming server expects ",

              body: JSON.stringify({ id }),
            }
          ).then((response) => {
            console.log(response.status);
            return response.json();
          });
          console.log(rawResponse);
          setText(rawResponse.text);
          setAnswer(rawResponse.you);
          setImage(
            rawResponse.url
              ? "https://" + rawResponse.url
              : window.location.origin + "/fail.png"
          );
          loadImage.current = true;
          // click(rawResponse.text);
        })();
      }
    }, [click, id, loadImage]);
    return (
      <Answer
        image={image}
        answer={answer}
        text={text}
        onClick={async () => {
          navigate("/");
        }}
      />
    );
  },
  () => false
);
