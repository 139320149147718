import { t } from "i18next";
import { Answer } from "../ui/answer/answer";
import { Input } from "../ui/input/input";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { bgLoadState, idState } from "../store";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

export const Main = () => {
  const content = useRef<HTMLDivElement>(null);
  const setLoad = useSetRecoilState(bgLoadState);
  const [image, setImage] = useState("");
  const [answer, setAnswer] = useState("");
  const [text, setText] = useState("");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [id, setId] = useRecoilState(idState);
  useEffect(() => {
    const div = content.current;
    if (answer.length) {
      if (div) {
        div.style.opacity = "1";
        navigate(`/dream/${id}`);
      }
      setLoad(false);
    }
  }, [answer]);
  const location = useLocation();
  return (
    <div className="content" ref={content}>
      <title>Dreaime</title>
      <meta name="description" content="The DreamBook AI" />
      <meta property="og:title" content="Dreaime" />
      <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />

      {answer.length > 0 ? null : (
        // <Answer
        //   image={image}
        //   answer={answer}
        //   text={text}
        //   onClick={async () => {
        //     // setLoad(true);
        //     const div = content.current;
        //     if (div) {
        //       div.style.opacity = "0";
        //     }
        //     await new Promise((res) => setTimeout(res, 400));
        //     setAnswer("");
        //     setImage("");
        //     if (div) {
        //       div.style.opacity = "1";
        //     }
        //     setLoad(false);
        //   }}
        // />
        <>
          <div className="App-logocont">
            <h2>{t("header", "Hello, Stranger...")}</h2>
            <p>
              <span style={{ paddingBottom: 20 }}>
                {t("headerspan1", "How did you sleep last night?")}
              </span>
              <span>{t("headerspan2", "Where have you been?")}</span>
            </p>
          </div>
          <div className="inputCont">
            <Input
              setImage={setImage}
              setAnswer={setAnswer}
              setText={setText}
              onClick={async () => {
                setLoad(true);
                const div = content.current;
                if (div) {
                  div.style.opacity = "0";
                }
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
