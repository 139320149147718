import { useTranslation } from "react-i18next";

import "./changeLang.css";
import { SetStateAction, memo, useEffect, useState } from "react";
import { menuOpenState } from "../menu/menu";
import { useRecoilState } from "recoil";

export const ChengeLang = memo(() => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  console.log(i18n.language);
  const languageNames = new Intl.DisplayNames(["en"], {
    type: "language",
  });
  const getNameLang = (lang: string) => {
    const languageNames = new Intl.DisplayNames([lang], {
      type: "language",
    });
    return languageNames.of(lang)?.toLocaleLowerCase();
  };
  const [allLangs, setLngs] = useState(["en"]);
  useEffect(() => {
    i18n.services.backendConnector.backend.getLanguages(
      (err: any, ret: any) => {
        if (err) return; // TODO: handle err...
        console.log(ret);
        setLngs(Object.keys(ret));
      }
    );
  }, []);

  const [openMenu, setOpenMenu] = useRecoilState(menuOpenState);
  return (
    <div className="changeLang">
      <div className={"allLangs " + (open ? "show" : "hide")}>
        {allLangs.map((el) => (
          <div
            key={el}
            onClick={() => {
              changeLanguage(el);
              setOpen(false);
            }}
          >
            {getNameLang(el)}
          </div>
        ))}
      </div>
      <div
        onClick={(e) => {
          console.log(open);
          if (!open) {
            setOpenMenu(false);
          }
          setOpen((open) => !open);
        }}
      >
        {`${languageNames.of(i18n.language)?.toLocaleLowerCase()}`}
      </div>
    </div>
  );
});
