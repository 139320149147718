import { atom } from "recoil";

export const bgLoadState = atom({
  key: "textState",
  default: false,
});

export const idState = atom({
  key: "idState",
  default: "",
});
