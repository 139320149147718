import React, { useEffect, useRef, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Input } from "./ui/input/input";
import { Lunarphase } from "./ui/lunarphase/lunarphase";
import { Background } from "./ui/bg/bg";
import { Answer } from "./ui/answer/answer";
import { useAddToHomescreenPrompt } from "./hooks/addToHomeScreen";
import { useTranslation, Trans } from "react-i18next";
import { Menu, menuOpenState } from "./ui/menu/menu";
import { Logo } from "./ui/logo";
import { Footer } from "./ui/Footer";
import {
  Link,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  useLocation,
  useParams,
} from "react-router-dom";
import { Main } from "./page/main";
import { Codex } from "./page/codex";
import { useRecoilState, useRecoilValue } from "recoil";
import { bgLoadState } from "./store";
import { Dream } from "./page/dream";
import { DreaimeLogo } from "./ui/dreaaimelogo";
import { Contacts } from "./page/contacts";
import { ChengeLang } from "./ui/ChangeLang/changeLang";
import { Pp } from "./page/pp";
import "share-buttons";
import { TransitionGroup, CSSTransition } from "react-transition-group";

export const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      { path: "/", element: <Main /> },
      { path: "/dream/:id", element: <Dream /> },
      { path: "/profile", element: <div>profile</div> },
      { path: "/pricing", element: <div>pricing</div> },
      { path: "/codex", element: <Codex /> },
      { path: "/contacts", element: <Contacts /> },
      { path: "/PrivacyPolicy", element: <Pp /> },
    ],
  },
]);

function App() {
  const load = useRecoilValue(bgLoadState);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  const location = useLocation();
  const [open, setOpen] = useRecoilState(menuOpenState);
  const historyItem = localStorage.getItem("history") || "[]";
  const history = JSON.parse(historyItem) || [];
  const { prompt, promptToInstall } = useAddToHomescreenPrompt();
  return (
    <div className="App">
      <Background load={load} open={open} />
      <header className="App-header">
        <Link className="App-link" to={`/`}>
          <DreaimeLogo />
        </Link>
        <Menu></Menu>
        <Lunarphase
          open={open}
          onClick={() => {
            setOpen(!open);
          }}
        />
      </header>
      <section className={"main" + (open ? " blure" : "")}>
        <TransitionGroup component={null}>
          <CSSTransition key={location.key} classNames={"fade"} timeout={300}>
            <Routes location={location}>
              {/* { path: "/", element: <Main /> },
      { path: "/dream/:id", element: <Dream /> },
      { path: "/codex", element: <Codex /> },
      { path: "/contacts", element: <Contacts /> },
      { path: "/PrivacyPolicy", element: <Pp /> }, */}
              <Route path="/" element={<Main />} />
              <Route path="/codex" element={<Codex />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/PrivacyPolicy" element={<Pp />} />
              <Route path="/dream/:id" element={<Dream />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </section>
      <div
        onClick={() => {
          setOpen(false);
        }}
        style={{
          position: "fixed",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
          background: "#00000055",
          transition: "0.4s",
          opacity: open ? 1 : 0,
          pointerEvents: open ? "all" : "none",
        }}
      ></div>
      <Footer></Footer>
    </div>
  );
}

export default App;
