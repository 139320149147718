import { Moon } from "lunarphase-js";
import "./lunarphase.css";
import { FC, useEffect, useRef } from "react";
import { icons } from "./icons";
const phaseEmoji = Moon.lunarPhaseEmoji();
const phase = Moon.lunarPhase();
interface LunarphaseType {
  onClick: () => void;
  open: boolean;
}

export const Lunarphase: FC<LunarphaseType> = ({ onClick, open }) => {
  console.log(open);
  return (
    <div className="lunarphase menuOpen" onClick={onClick}>
      {/* <span>{icons[phaseEmoji]}</span> */}
      <label className={`bar ${open ? "check" : ""}`}>
        <span className="top"></span>
        <span className="middle"></span>
        <span className="bottom"></span>
      </label>
    </div>
  );
};
