import { Link } from "react-router-dom";
import "./codex.css";
export const Codex = () => {
  return (
    <section className="codex">
      <h2>
        codex{" "}
        <span>
          dre<span>ai</span>me
        </span>
      </h2>
      <div className="cont">
        <div className="card">
          <h3>1. Pornographic Scenes. </h3>
          The first test of intelligence - all obscene words and actions have
          literary equivalents; if you can find the right words that stay within
          the code of conduct, there's a good chance of getting a satisfactory
          result.
        </div>
        <div className="card">
          <h3>2. Naturalistic Descriptions of Violence Scenes. </h3>
          The second test of education - it’s understood that death, pain,
          murder are canonical symbols and should be operated within an ethical
          context, but avoid anatomy in the description of violence scenes for
          everything to be fin
        </div>
        <div className="card">
          <h3>3. Protection of Identity. If you dreamt. </h3>of a specific
          celebrity, try using the word "celebrity" as the subject - the child
          might not know who Gerard Depardieu is, and what does it matter,
          unless you personally know him.
        </div>
        <div className="card">
          <h3>4. Respect and Sensitivity. </h3>Do not create requests that could
          be offensive, discriminatory, or unacceptable. Respect the feelings
          and rights of your interlocutor, as well as everyone else. Do not
          teach the child swear words
        </div>
        <div className="card">
          <h3>
            5. Follow the generally accepted norms of moral and ethical human
            behavior.
          </h3>
          The young oracle - artificial intelligence - a child, so just be
          attentive and mindful. You are simply required to communicate with a
          child for an adult result, so filter.
        </div>
        <div className="card">
          <h3>6. The lunar phase impacts dream interpretation: </h3>dreams
          during a growing new moon may predict future events, while dreams
          during a waning moon often reflect the past. Nightmares after a full
          moon typically lose their scariness.
        </div>
      </div>
      <Link className="PrivacyPolicy" to={`/PrivacyPolicy`}>
        Privacy Policy
      </Link>
    </section>
  );
};
