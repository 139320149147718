import { FC } from "react";

export const Logo: FC = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 521.8 65.9"
    >
      <g>
        <path
          d="M59.2,29.8c-9.9-9.1-22.9-13.5-38.7-13.6c-0.1,0-0.1,0-0.2,0c-5.1,0-9.2,4.1-9.2,9.2s4.1,9.2,9.1,9.2l0,0
		c10,0,17.8,2.9,23.6,8.7c5.7,5.8,8.5,13.5,8.4,22.8h22.1c0-0.2,0-0.5,0-0.7C74.3,50.7,69.3,38.9,59.2,29.8z"
        />
        <path
          d="M121.5,50.3c0-2.4-0.7-4.4-2.2-6.1c-1.5-1.5-3.4-2.3-5.9-2.3c-1.1,0-2.8,0.4-4.9,1.2
		c-4.2,1.5-8.2,4.8-12.1,9.8l0.2-1.4c0-2.7-1-4.9-2.9-6.8c-1.9-1.9-4.3-2.7-7-2.7s-4.9,1-6.8,2.8c-1.9,2-2.8,4.3-2.8,6.9V66h20.6
		c1.4-1.2,3.3-2.5,5.6-3.7c3.1-1.4,6.1-2.3,9.1-2.7c2.9-0.4,5.1-1.4,6.7-3.1C120.7,54.8,121.5,52.7,121.5,50.3z"
        />
        <path
          d="M153.3,41.6c-11,0-20.1,3.6-27.4,10.8c-4.1,4.1-7,8.7-8.8,13.7h20c0.9-1.8,2.2-3.6,3.7-5
		c3.2-3.1,7.3-4.5,12.5-4.5c5.5,0,9.8,1.5,13,4.5c1.6,1.5,2.8,3.2,3.7,5.1h18.9c-1.6-5.4-4.7-10.2-9.1-14.5
		C172.8,44.9,163.9,41.6,153.3,41.6z"
        />
        <path
          d="M247,48c-5.9-4.2-13.7-6.4-23.2-6.4c-10.2,0-19.3,2.6-27.4,7.7c-2.3,1.4-3.5,3.5-3.5,6.2
		c0,1.9,0.7,3.7,2.1,5.1c1.4,1.4,3.2,2.1,5.2,2.1c1.4,0,2.7-0.4,3.8-1.1c5.3-3.2,10.8-4.7,16.4-4.7c4.9,0,8.8,1.1,11.8,3.3
		c2.2,1.6,3.5,3.6,4.1,6H256C255.4,57.9,252.5,51.8,247,48z"
        />
        <path
          d="M305,18.3c-3.3-2.1-6.9-3.3-10.9-3.3s-7.6,1.1-10.9,3.3c-3.3,2.1-5.6,5-7,8.6l-15.7,39.2h22l11.6-31.8
		l11.6,31.8h22L312,26.9C310.6,23.3,308.2,20.5,305,18.3z"
        />
        <path
          d="M341.5,15.3c-3.1,0-5.7,1.1-7.8,3.1c-2.1,2-3.2,4.5-3.2,7.3v40.5h21.7V25.7c0-2.9-1-5.3-3.1-7.3
		S344.5,15.3,341.5,15.3z"
        />
        <path
          d="M466.4,63.1c0-7-1.9-12.4-5.6-16.1c-3.7-3.7-9.1-5.5-16.1-5.5c-5.1,0-10,1.3-14.7,3.8s-8.3,5.6-10.7,9.3
		c-3.1-8.7-9.9-13-20.5-13c-5.1,0-9.9,1.2-14.4,3.6c-4.4,2.3-7.6,4.8-9.6,7.3v-0.8c0-2.7-1-4.9-2.9-6.8c-1.9-1.9-4.3-2.8-7-2.8
		s-4.9,1-6.8,2.8s-2.8,4.1-2.8,6.8v14.4h19.8c1.7-2.1,3.8-4,6.3-5.5c2.8-1.6,5.6-2.4,8.6-2.4c6.1,0,9.7,2.6,10.7,7.9h20.2
		c4.1-5.2,9.1-7.9,15-7.9c6.1,0,9.7,2.6,10.7,7.9h19.9v-2.9L466.4,63.1L466.4,63.1z"
        />
        <path
          d="M511.4,49.1c0-3.6-2.6-6.7-6-7.4c0-0.1,0-0.1,0-0.1s-0.4,0-1.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.4,0
		c-4.6,0.1-16.9,1.2-25.5,10.8c-3.8,4.2-7,8.7-8.8,13.7h19.9c0.9-1.8,2.2-3.6,3.8-5.1c2.8-2.7,6.2-4.1,10.5-4.4c0.2,0,0.4,0,0.5,0
		c0.4,0,0.8,0,1.1-0.1c0.1,0,0.2,0,0.3,0l0,0C508.8,55.8,511.4,52.8,511.4,49.1z"
        />
      </g>
    </svg>
  );
};
