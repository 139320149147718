import { off } from "process";
import { FC, useEffect, useRef, useState } from "react";

export const DreaimeLogo: FC = () => {
  const opacity = useRef(0);
  const opacitySpeed = useRef(0.005);
  const div1 = useRef<HTMLDivElement>(null);

  const div2 = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const f = () => {
      requestAnimationFrame((frame) => {
        opacity.current += opacitySpeed.current;
        if (opacity.current > 2 || opacity.current < -1) {
          opacitySpeed.current *= -1;
        }
        if (div1.current && div2.current) {
          div1.current.style.opacity = "" + opacity.current;
          div2.current.style.opacity = "" + (1 - opacity.current);
        }
        f();
      });
    };
    f();
  }, []);
  return (
    <div
      style={{
        display: "inline-flex",
        position: "relative",
        textAlign: "left",
      }}
    >
      <div
        style={{ display: "inline", position: "relative", textAlign: "left" }}
      >
        dre
      </div>
      <div
        ref={div1}
        style={{
          opacity: 0,
          position: "relative",
          whiteSpace: "nowrap",
          width: 0,
        }}
      >
        <span>ai</span>me.com
      </div>
      <div ref={div2} style={{ position: "relative", whiteSpace: "nowrap" }}>
        ambook <span>ai</span>
      </div>
    </div>
  );
};
