import { FC, useState } from "react";
import { Logo } from "../logoVertical";
import "./menu.css";
import { changeLanguage } from "i18next";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { atom, useRecoilState } from "recoil";
export const menuOpenState = atom({ default: false, key: "MenuOpen" });

export const Menu: FC = () => {
  const [open, setOpen] = useRecoilState(menuOpenState);
  const { t, i18n } = useTranslation();
  return (
    <div
      className={`menu ${open ? " open" : ""}`}
      style={{ maxWidth: open ? 330 : 40 }}
      onClick={() => setOpen(!open)}
    >
      <div className="logo">
        <Logo></Logo>
      </div>
      <div className="linkCont" style={{}}>
        <span>
          <Link className="disable" to={`profile`}>
            {t("profile", "profile")}
          </Link>
        </span>
        <span>
          <Link className="disable" to={`pricing`}>
            {t("pricing", "pricing")}
          </Link>
        </span>
        <span>
          <Link to={`codex`}>
            {t("codex", "codex")}
            <div className="dreaime">
              dre<span>ai</span>me
            </div>
          </Link>
        </span>
        <span>
          <Link to={`contacts`}>{t("contacts", "contacts")}</Link>
        </span>
        <span>{/* <ChengeLang></ChengeLang> */}</span>
      </div>
    </div>
  );
};
